import React from "react";
import Header from "../components/Header";
import PageTitle from "../components/PageTitle";
import Contact from "../components/Contact";
import ContactDetails from "../components/ContactDetails";
import Footer from "../components/Footer";
import Group from "../components/Group";
import ServiceBlock from "../components/ServiceBlock";
import image from "../assets/images/contact-main.png";
import Section1 from "../components/Section1";
import wpsec1 from "../assets/images/contactus.png";
import wpsec4 from "../assets/images/support.png";
import ServiceBlockLeft from "../components/ServiceBlockLeft";
import { ScrollRestoration } from "react-router-dom";

const ContactUsScreen = () => {
  return (
    <>
      <Header />
      <PageTitle
        title="Contact Us"
        description="Introducing Rbsec, your steadfast ally in the digital space. Our seasoned team of cybersecurity professionals is dedicated to the comprehensive protection of your IT infrastructure and digital assets. We blend experience with innovation to safeguard your future in the rapidly evolving cyber landscape.        "
        pageTitle="Contact Us"
        path="contact"
      />
      <ServiceBlock
        title="Contact Us: Connect with RBsec for Your Cybersecurity Needs"
        description="At RBsec, we understand the value of open communication and collaboration. Whether you're seeking expert advice, in need of our services, or have a general inquiry, we invite you to get in touch with us. We're here to assist you in creating a secure digital environment for your business."
        image={image}
        subheading="Connect with RBSec"
        altText="Contact Us: Connect with RBsec for Your Cybersecurity Needs"
      />
      <Section1
        subheading="Contact"
        heading="Reach Out To Us"
        para="If you're interested in learning more about our services, discussing a specific cybersecurity concern, or would like to schedule a consultation with our team of experts, don't hesitate to reach out. We value your questions and feedback."
        imageUrl={wpsec1}
        altText="Reach Out To Us"
      />
      <ContactDetails />
      <ServiceBlockLeft
        title="Customer Support"
        description="For ongoing projects, our dedicated customer support team is available to provide you with prompt assistance. If you're experiencing any difficulties or have any questions, please contact our support team at support@rbsec.com. At RBsec, we're committed to delivering excellent customer service and providing expert guidance in cybersecurity. Reach out to us today to explore how we can help safeguard your digital assets."
        image={wpsec4}
        subheading="Support"
        altText="Customer Support"
      />
      <Contact />
      <Footer />
      <Group />
      <ScrollRestoration />
    </>
  );
};

export default ContactUsScreen;
