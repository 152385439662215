import React, { useState, useEffect } from "react";
import servicesImage1 from "../assets/images/services-img1.png";
import servicesImage2 from "../assets/images/mobile-app (1).png";
import servicesImage3 from "../assets/images/cloud.png";
import servicesImage4 from "../assets/images/services-img4.png";
import servicesImage5 from "../assets/images/network.png";
import servicesImage6 from "../assets/images/services-img6.png";
import servicesImage8 from "../assets/images/wireless.png";
import secureit from "../assets/images/secure-it.png";

import "../App.css";
import { Link } from "react-router-dom";

const slidesDataSet = [
  {
    image: servicesImage1,
    slideTitle: "Web Application Penetration Testing",
    path: "/web-application-pentest",
    description:
      "Web applications are critical for business success but are also attractive targets fo...",
  },
  {
    image: servicesImage2,
    slideTitle: "Mobile Application Penetration Testing",
    path: "/mobile-app-pentest",
    description:
      "In todays smartphone dominated era mobile applications have revolutionized the way we...",
  },
  {
    image: servicesImage3,
    slideTitle: "Cloud Security Assessment",
    path: "/cloud-pentest",
    description:
      "Our team of experts, in cloud security is here to assist you in enhancing the security ...",
  },
  {
    image: servicesImage4,
    slideTitle: "Red Team Assessment",
    path: "/red-team-pentest",
    description:
      "Our primary aim, during a red team exercise is to assess your organizations security mea...",
  },
  {
    image: servicesImage5,
    slideTitle: "Infrastructure Security Testing",
    path: "/infrastructure-security",
    description:
      "Infrastructure Security Testing services are performed to detect and validate vulnerabilities...",
  },
  {
    image: servicesImage8,
    slideTitle: "Wireless Security Testing",
    path: "/wireless-pentest",
    description:
      "Wireless is not limited to Wifi only. Wireless networks provide enhanced user productivity...",
  },
  {
    image: servicesImage6,
    slideTitle: "Secure Code Analysis",
    path: "/source-code-pentest",
    description:
      "Our team specializes in discovering and analyzing vulnerabilities within the source code th...",
  },
  {
    image: secureit,
    slideTitle: "IT Security Training",
    path: "/it-security-assesment",
    description:
      "Our IT Security Training programs are designed to build and enhance your team's cybersecurit...",
  },
];

const Slider = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const sliderInterval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % slidesDataSet.length);
    }, 3000);

    return () => {
      clearInterval(sliderInterval);
    };
  }, []);

  const getSlideData = (index) => {
    const slideData = [];
    for (let i = index; i < index + 7; i++) {
      slideData.push(slidesDataSet[i % slidesDataSet.length]);
    }
    return slideData;
  };

  return (
    <div className="slider-container">
      <div
        className="slider-track"
        style={{
          transform: `translateX(-(${currentIndex})%)`,
          transition: "3s ease-in-out",
        }}
      >
        {getSlideData(currentIndex).map((slide, index) => (
          <div key={index} className="slide">
            <div className="slide-item">
              <div className="slider-image-div">
                <img
                  src={slide.image}
                  alt={slide.slideTitle}
                  className="slider-box"
                />
              </div>
              <div className="slider-title-div">
                <h5 className="slide-title">{slide.slideTitle}</h5>
              </div>
              <p className="slide-description">{slide.description}</p>
              <Link to={slide.path}>
                <button className="button-wrapper">Read More</button>
              </Link>
            </div>
          </div>
        ))}
      </div>
      <div className="indicators">
        {slidesDataSet.map((_, index) => (
          <div
            key={index}
            className={`indicator ${index === currentIndex ? "active" : ""}`}
            onClick={() => setCurrentIndex(index)}
          />
        ))}
      </div>
    </div>
  );
};

export default Slider;
