import React from "react";
import { Link } from "react-router-dom";
import "../App.css";

const NavLink = ({ path, title, extra }) => {
  return (
    <li className={`nav-link ${extra ?? ""}`}>
      <Link className="link" to={path}>
        {title}
      </Link>
    </li>
  );
};

export default NavLink;
