import React, { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";

const ServiceBlockLeft = ({
  title,
  description,
  image,
  subheading,
  altText,
}) => {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);
  return (
    <div className="about-page-container">
      <div className="about-page-section-1" data-aos="fade-right">
        <div className="about-page-section-1-right">
          <img src={image} alt={altText} className="sec1-img" />
        </div>
        <div className="about-page-section-1-left">
          <div className="section-1-right-sub-heading-container adjust">
            <p className="section-1-right-sub-heading">{subheading}</p>
          </div>

          <div className="section-1-right-heading-container">
            <h1 className="section-1-right-heading">{title}</h1>
          </div>
          <p
            style={{
              fontFamily: "WebRegular",
              fontSize: "18px",
              lineHeight: "27px",
              lineSpacing: "0.18px",
              color: "#afb6d2",
              marginTop: "0px",
              marginBottom: "0px",
              marginLeft: "10px",
            }}
            className="section-1-right-sub-para"
          >
            {description}
          </p>
        </div>
      </div>
    </div>
  );
};

export default ServiceBlockLeft;
