import React from "react";
import Header from "../components/Header";
import PageTitle from "../components/PageTitle";
import OurServices from "../components/OurServices";
import Footer from "../components/Footer";
import Group from "../components/Group";
import { ScrollRestoration } from "react-router-dom";

const ServicesScreen = () => {
  return (
    <>
      <Header />
      <PageTitle
        title="Services"
        description="Join us on this journey of proactive cybersecurity, and let's secure your digital future together. Contact us today to learn how we can protect your digital assets from the unseen threats lurking in the digital shadows."
        pageTitle="Services"
        path="services"
      />
      <OurServices />
      <Footer />
      <Group />
      <ScrollRestoration />
    </>
  );
};

export default ServicesScreen;
