import React from "react";
import Slider from "./Slider";

const OurServices = () => {
  return (
    <div className="services-container">
      <div className="services-inner-container">
        <p className="services-tagline">OUR SERVICES</p>
        <h2 className="services-title">Cyber Security Solutions</h2>
        <div className="services-slider">
          <Slider />
        </div>
      </div>
    </div>
  );
};

export default OurServices;
