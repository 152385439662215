import React from "react";
import Header from "../components/Header";

const OurTeamScreen = () => {
  return (
    <>
      <Header />
    </>
  );
};

export default OurTeamScreen;
