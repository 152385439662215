import React from "react";
import Header from "../components/Header";
import Hero from "../components/Hero";
import OurServices from "../components/OurServices";
import About from "../components/About";
import Contact from "../components/Contact";
import Group from "../components/Group";
import Footer from "../components/Footer";
import { ScrollRestoration } from "react-router-dom";

const HomeScreen = () => {
  return (
    <>
      <Header />
      <Hero />
      <OurServices />
      {/* <Partners /> */}
      <About />
      <Contact />
      <Footer />
      <Group />
      <ScrollRestoration />
    </>
  );
};

export default HomeScreen;
