import React, { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import section1Image from "../assets/images/about-secs-1.png";
import bgImage from "../assets/images/what-we-do-vector.png";
import bgImage1 from "../assets/images/banner-bg-1.png";
import whoWeAre from "../assets/images/who-we-are-image.png";
import whatWeDo from "../assets/images/what-we-do-image.png";
import protect from "../assets/images/what-we-do-protect-image.png";
import approach from "../assets/images/approach.png";
import values from "../assets/images/values.png";
import commitment from "../assets/images/commitment.png";

import "../App.css";
import Protect from "./Protect";

const AboutPage = () => {
  const protectData = [
    {
      title: "Web and Mobile Application Security",
      description: "Penetration Testing for Web and Mobile Application.",
      image: protect,
    },
    {
      title: "Infrastructure and Wireless Security",
      description:
        "Penetration Testing for Infrastructure and Wireless Security.",
      image: protect,
    },
    {
      title: "Cloud Security Assessment",
      description:
        "Ensuring Cloud Security: Thorough Assessment, Maximum Digital Protection",
      image: protect,
    },
    {
      title: "Secure Code Analysis",
      description:
        "Comprehensive Secure Code Analysis for Robust Digital Security Solutions.",
      image: protect,
    },
    {
      title: "Red Team Assessment",
      description:
        "Strategic Red Team Assessment for Enhanced Security and Preparedness.",
      image: protect,
    },
    {
      title: "Full-Suite Social Engineering Campaign Planning",
      description:
        "Strategic Social Engineering Campaign Planning: Comprehensive and Impactful Solutions.",
      image: protect,
    },
  ];

  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);
  return (
    <div className="about-page-container">
      <div className="about-page-section-1">
        <div className="about-page-section-1-left">
          <p className="about-tagline">ABOUT US</p>
          <h2 className="section-title">
            RBsec - Your Trusted Partner in
            <span className="highlight"> Cybersecurity</span>
          </h2>
          <p className="about-right-description">
            At RBsec, we believe in the power of secure digital environments to
            unlock potential and drive progress. Our mission is to provide
            robust, tailored cybersecurity solutions that protect your business
            assets and empower your growth in the digital age.
          </p>
        </div>
        <div className="about-page-section-1-right" data-aos="fade-left">
          <img
            src={section1Image}
            className="about-section-image"
            alt="RBSEC Team"
          />
        </div>
      </div>
      <div className="about-page-section-1">
        <div
          className="hero-container"
          style={{
            backgroundImage: `url(${bgImage})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "50%",
            backgroundPosition: "left top",
          }}
        >
          <div className="detail-container">
            <div className="content-container">
              <p
                className="about-tagline"
                style={{
                  marginTop: "10px",
                  marginBottom: "10px",
                }}
              >
                WHO WE ARE
              </p>
              <h3
                className="hero-title"
                style={{
                  marginTop: "10px",
                  marginBottom: "10px",
                }}
              >
                Industry Expertise, Advanced Technologies, And Strategic
                Approaches
              </h3>
              <p
                className="hero-description"
                style={{
                  marginTop: "10px",
                  marginBottom: "10px",
                }}
              >
                RBsec is a leading provider of comprehensive cybersecurity
                services. With a dedicated team of seasoned professionals, we
                combine industry expertise, advanced technologies, and strategic
                approaches to help businesses fortify their digital landscapes.
              </p>
            </div>
            {/* <div className="row-space-between">
              <div className="col">
                <h3 className="count-title">8</h3>
                <p className="count-para">Services</p>
              </div>
              <div className="col">
                <h3 className="count-title">4+</h3>
                <p className="count-para">Trainings</p>
              </div>
              <div className="col">
                <h3 className="count-title">∞</h3>
                <p className="count-para">Results</p>
              </div>
            </div> */}
          </div>
          <div className="about-page-section-1-right" data-aos="fade-left">
            <img
              src={whoWeAre}
              className="about-section-image"
              alt="who-we-are"
            />
          </div>
        </div>
      </div>
      <div className="about-page-section-2">
        <div
          className="hero-container"
          style={{
            backgroundImage: `url(${bgImage1})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "100%",
            backgroundPosition: "left top",
          }}
        >
          <div className="about-page-section-1-right">
            <img
              src={whatWeDo}
              data-aos="fade-left"
              className="about-section-image"
              alt="what we do"
              // style={{ height: "70%" }}
            />
          </div>
          <div className="detail-container">
            <div className="content-container">
              <p
                className="about-tagline"
                style={{
                  marginTop: "10px",
                  marginBottom: "10px",
                }}
              >
                WHAT WE DO
              </p>
              <h3
                className="hero-title"
                style={{
                  marginTop: "10px",
                  marginBottom: "10px",
                }}
              >
                Security is Not an Afterthought
              </h3>
              <p
                className="hero-description"
                style={{
                  marginTop: "10px",
                  marginBottom: "10px",
                }}
              >
                We offer an extensive range of cybersecurity services, including
                but not limited to, Web and Mobile Application Penetration
                Testing, Red Team Assessments, Cloud Security Assessments,
                Secure Code Reviews, Infrastructure Security, Wireless Security
                Testing, and IT Security Training.
              </p>
              <p
                className="hero-description"
                style={{
                  marginTop: "10px",
                  marginBottom: "10px",
                }}
              >
                Our services are designed to identify vulnerabilities, protect
                assets, mitigate risks, and ensure compliance. With RBsec,
                security is not an afterthought but a core business strategy.
              </p>
            </div>

            <div
              className="row-wrap"
              style={{
                width: "100%",
                boxSizing: "border-box",
              }}
            >
              {protectData.map((item) => (
                <Protect
                  title={item.title}
                  description={item.description}
                  image={item.image}
                />
              ))}
            </div>
          </div>
        </div>
      </div>

      {/* Section 3rd ---------------- */}
      <div className="about-page-section-1">
        <div
          className="hero-container section-img"
          style={{
            backgroundImage: `url(${bgImage})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "50%",
            backgroundPosition: "left top",
          }}
        >
          <div className="section-3">
            <div className="col-30">
              <h3
                className="hero-title"
                style={{
                  marginTop: "10px",
                  marginBottom: "10px",
                }}
              >
                Our Approaches, Commitment, and Values
              </h3>
            </div>
            <div className="bottom-container">
              <div className="col-70">
                <div className="box-20">
                  <img
                    src={approach}
                    alt="Our Approach"
                    className="about-img-responsive"
                  />
                  <h1 className="box-20-title">Our Approach</h1>
                  <p className="box-20-para">
                    We adopt a proactive, multi-layered approach to
                    cybersecurity. Our team employs the latest methodologies and
                    tools to test your systems and networks for potential
                    vulnerabilities. By identifying these vulnerabilities, we
                    can devise and implement strategic countermeasures that
                    offer robust protection against cyber threats.
                  </p>
                </div>
                <div className="box-20">
                  <img
                    src={commitment}
                    alt="Our Commitment"
                    className="about-img-responsive"
                  />
                  <h1 className="box-20-title">Our Commitment</h1>
                  <p className="box-20-para">
                    Our commitment extends beyond simply securing your IT and
                    digital assets. We strive to empower your team through our
                    comprehensive IT Security Training programs, ensuring they
                    have the knowledge and skills to maintain your
                    organization's cyber resilience.
                  </p>
                </div>
                <div className="box-20">
                  <img
                    src={values}
                    alt="Our Values"
                    className="about-img-responsive"
                  />
                  <h1 className="box-20-title">Our Values</h1>
                  <p className="box-20-para">
                    We are driven by values of integrity, excellence, and client
                    satisfaction. We believe in building long-term relationships
                    with our clients based on mutual trust and respect. To us,
                    your security is not just a business requirement but a
                    commitment we make every day.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="about-bottom">
        <p className="box-20-para">
          In the complex and rapidly evolving world of cybersecurity, RBsec
          stands as your trusted partner. We provide the expert guidance and
          effective solutions you need to navigate the digital landscape with
          confidence and peace of mind.
        </p>
      </div>
    </div>
  );
};

export default AboutPage;
