import React from "react";
import aboutRightImage from "../assets/images/about-dot-vector.png";
import rightCircle from "../assets/images/right-circle.png";
import { AiFillCheckCircle } from "react-icons/ai";
import aboutImage1 from "../assets/images/about/about-image-1.png";
import aboutImage2 from "../assets/images/about/about-image-2.png";
import aboutImage3 from "../assets/images/about/about-image-3.jpg";
import cross from "../assets/images/3d-cross-design.png";

const About = () => {
  const leftPoints = [
    { point: "Web Application Security Assessment" },
    { point: "Mobile Application Security Assessment " },
    { point: "Cloud Security Assessment" },
    { point: "Red Team Assessment" },
  ];
  const rightPoints = [
    { point: "Infrastructure Security Assesment" },
    { point: "Wireless Security Testing" },
    { point: "Secure Code Analysis" },
    { point: "IT Security Training" },
  ];

  return (
    <div className="about-main-container">
      <div className="about-inner-container">
        <div
          className="about-image-section"
          style={{
            backgroundImage: `url(${aboutRightImage})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <div className="left-top-image-section">
            <img
              src={aboutImage1}
              alt="RBSEC About"
              className="about-image-1"
            />
            <img
              src={aboutImage2}
              alt="RBSEC About"
              className="about-image-2"
            />
          </div>
          <div className="left-bottom-image-section">
            <img src={cross} alt="RBSEC About" className="cross-image" />
            <img
              src={aboutImage3}
              alt="RBSEC About"
              className="about-image-3"
            />
          </div>
        </div>
        <div className="about-right-section">
          <img
            className="about-circle"
            alt="curve-circle"
            src={rightCircle}
            width="122px"
            height="95px"
          />
          <div className="about-right-inner">
            <p className="about-tagline">ABOUT US</p>
            <h2 className="about-title">
              Professionals Committed to Safeguarding Your IT and Digital Assets
            </h2>
            <p className="about-right-description">
              Secure your digital assets through a range of expert services,
              including comprehensive security assessments, evaluations,
              analysis, and professional training to safeguard against evolving
              threats.
            </p>
            <div className="about-points">
              <div className="points-left">
                {leftPoints.map((point) => (
                  <div className="point-container" key={point.point}>
                    <span className="icon-container">
                      <AiFillCheckCircle className="check-icon" />
                    </span>
                    <span className="point-name">{point.point}</span>
                  </div>
                ))}
              </div>
              <div className="points-right">
                {rightPoints.map((point) => (
                  <div className="point-container" key={point.point}>
                    <span className="icon-container">
                      <AiFillCheckCircle className="check-icon" />
                    </span>
                    <span className="point-name">{point.point}</span>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
