import React, { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import heroImage from "../assets/images/right-image-hero.png";
import bgImage from "../assets/images/dots-vector-background.png";
import { Link } from "react-router-dom";
import "../App.css";

const Hero = () => {
  useEffect(() => {
    Aos.init({ duration: 500 });
  }, []);
  return (
    <>
      <div className="hero">
        <div
          className="hero-container"
          style={{
            backgroundImage: `url(${bgImage})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "70%",
            backgroundPosition: "right top",
          }}
        >
          <div className="hero-social"></div>
          <div className="detail-container">
            <div className="content-container">
              <h1 className="hero-title">
                Securing Your Digital Landscape with Expert Cybersecurity
                Solutions.
              </h1>
              <p className="hero-description">
                We are Rbsec, a team of experienced security professionals
                committed to safeguarding your IT and digital assets.
              </p>
              <Link to="/contact" className="link">
                <button className="contact-us">Contact Us</button>
              </Link>
            </div>
          </div>
          <div className="top-image-container" data-aos="fade-left">
            <img src={heroImage} className="hero-image" alt="RB Security" />
          </div>
        </div>
      </div>
    </>
  );
};

export default Hero;
