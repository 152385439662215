import React from "react";
import OurServices from "../components/OurServices";
import ServiceBlock from "../components/ServiceBlock";
import PageTitle from "../components/PageTitle";
import Header from "../components/Header";
import image from "../assets/images/SC1.png";
import Footer from "../components/Footer";
import Group from "../components/Group";
import Section1 from "../components/Section1";
import wpsec1 from "../assets/images/SC2.png";
import wpsec2 from "../assets/images/SC3.png";
import wpsec3 from "../assets/images/time.png";
import wpsec4 from "../assets/images/SC4.png";
import wpsec5 from "../assets/images/SC5.png";
import Section2 from "../components/Section2";
import Section3 from "../components/Section3";
import Section4 from "../components/Section4";
import ServiceBlockLeft from "../components/ServiceBlockLeft";
import { ScrollRestoration } from "react-router-dom";

const SourceCodeScreen = () => {
  return (
    <>
      <Header />
      <PageTitle
        title="Secure Code Analysis"
        description="Our team specializes in discovering and analyzing vulnerabilities within the source code through static code analysis."
        pageTitle="Secure Code Analysis"
        path="source-code-pentest"
      />
      <ServiceBlock
        title="Reinforcing Your Digital Foundations: Secure Code Review"
        description="Secure Code Review is an indispensable step in the development process to identify vulnerabilities early on. Our Secure Code Review service ensures the safety of your software applications through meticulous examination and analysis of the source code."
        image={image}
        subheading="SECURE CODE REVIEW"
        altText="Reinforcing Your Digital Foundations: Secure Code Review"
      />
      <Section1
        subheading="Process"
        heading="Why Secure Code Review is Essential"
        para="Secure Code Review is the process of inspecting, scanning, and evaluating source code to detect weaknesses and defects. It employs the best secure coding practices to bolster the security of the software and defend it against potential cyberattacks."
        imageUrl={wpsec1}
        altText="Why Secure Code Review is Essential"
      />
      <Section2
        subheading="Trust"
        heading="Building User Trust Through Secure Products"
        para="In the digital age, end-users prioritize security when choosing products. Our Secure Code Review service helps you build robust and secure software applications, enhancing your brand's trust quotient in the competitive marketplace."
        imageUrl={wpsec2}
        altText="Building User Trust Through Secure Products"
      />
      <Section3
        subheading="eliminating these issues"
        heading="Preserving Time, Finances, and Reputation"
        para="Our Secure Code Review service helps developers identify areas for improvement and critical mistakes that could lead to severe bugs. By spotting and eliminating these issues before moving to deployment or release, you can save significant time and money and protect your reputation."
        imageUrl={wpsec3}
        altText="Preserving Time, Finances, and Reputation"
      />
      <Section4
        subheading="proactive detection"
        heading="Proactive Error Identification"
        para="Our approach helps developers identify potential issues and errors early in the development process. This proactive detection allows for the timely rectification of errors before the deployment or release phase, saving potential troubleshooting time in the future."
        imageUrl={wpsec4}
        altText="Proactive Error Identification"
      />
      <ServiceBlockLeft
        title="In-depth Analysis for Unseen Bugs"
        description="Our Secure Code Review offers greater insight into your code or application, uncovering bugs that may go unnoticed during application testing. This thorough analysis ensures the overall stability and security of your software applications."
        image={wpsec5}
        subheading="Insight Into Code"
        altText="In-depth Analysis for Unseen Bugs"
      />
      <OurServices />
      <Footer />
      <Group />
      <ScrollRestoration />
    </>
  );
};

export default SourceCodeScreen;
