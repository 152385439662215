import React, { useRef } from "react";
import "../App.css";
import bgImage from "../assets/images/banner-bg-1.png";
import * as emailjs from "emailjs-com";

const Contact = () => {
  const [formStatus, setFormStatus] = React.useState("Send");
  const form = useRef();
  const onSubmit = async (e) => {
    e.preventDefault();
    setFormStatus("Submitting...");

    emailjs
      .sendForm(
        "service_1wxjztp",
        "template_da9sgod",
        form.current,
        "r-eH5NpsMNoSIxdNm"
      )
      .then(
        (result) => {
          setFormStatus("Submitted");
        },
        (error) => {
          console.log(error.text);
        }
      );
  };
  return (
    <div className="contact-container">
      <div
        className="contact-inner-container"
        style={{
          backgroundImage: `url(${bgImage})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <p className="about-tagline">GET STARTED NOW</p>
        <h2 className="about-title">Send us a Message</h2>
        <div className="contact-form">
          <form onSubmit={onSubmit} ref={form}>
            <div className="contact-row">
              <div className="mb-3">
                <input
                  className="form-control"
                  placeholder="Name:"
                  type="text"
                  id="name"
                  name="user_name"
                  required
                />
              </div>
              <div className="mb-3">
                <input
                  className="form-control"
                  placeholder="Phone:"
                  type="phone"
                  id="phone"
                  name="user_phone"
                  required
                />
              </div>
              <div className="mb-3">
                <input
                  className="form-control"
                  placeholder="Email:"
                  type="email"
                  id="email"
                  name="user_email"
                  required
                />
              </div>
            </div>
            <div className="mbb-3">
              <textarea
                className="form-control-big"
                placeholder="Message:"
                id="message"
                name="message"
                required
              />
            </div>
            <button className="contact-us" type="submit">
              {formStatus}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Contact;
