import React from "react";
import image from "../assets/logo/rbsec-logo-1.png";

const Group = () => {
  return (
    <div className="group-container">
      <img src={image} alt="rbsec group" className="img-logo" />
      <p className="group-copyright">
        Copyright © 2023 RBSEC Org. All Rights Reserved.
      </p>
    </div>
  );
};

export default Group;
