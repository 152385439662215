import React, { useState, useRef } from "react";
import { IoMdArrowDropdown } from "react-icons/io";
import "../App.css";
import NavLink from "./NavLink";
import logo from "../assets/logo/rbsec-logo.svg";
import { useScrollDirection } from "../custom/useScrollDirection";
import { GiHamburgerMenu } from "react-icons/gi";
import { Link } from "react-router-dom";
import { useOnHoverOutside } from "../custom/useOnHoverOutside";

const Header = () => {
  const scrollDirection = useScrollDirection();
  const [isOpen, setIsOpen] = useState(false);

  document.title = "RBSEC - A Security Org";

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const dropdownRef = useRef(null); // Create a reference for dropdown container
  const [isMenuDropDownOpen, setMenuDropDownOpen] = useState(false);

  // Function to close dropdown
  const closeHoverMenu = () => {
    setMenuDropDownOpen(false);
  };

  useOnHoverOutside(dropdownRef, closeHoverMenu); // Call the hook

  return (
    <>
      <div
        className={`navbar-container ${
          scrollDirection === "down" ? "hide" : "show"
        }`}
      >
        <div className={`navbar`}>
          <div className="container">
            <div className="left-section">
              <Link to="/">
                <img className="logo" src={logo} alt="RBSEC" />
              </Link>
            </div>
            <div className="right-section">
              <div>
                <GiHamburgerMenu className="burger-menu" onClick={toggleMenu} />
              </div>
              <ul className="parent-link">
                <NavLink path="/" title="Home" />
                <NavLink path="/about" title="About Us" />

                <div ref={dropdownRef}>
                  <button
                    className="dropdown-button"
                    onMouseOver={() => setMenuDropDownOpen(true)}
                  >
                    <NavLink path="/services" title="Services" />
                    <IoMdArrowDropdown className="dropdown-icon" />
                  </button>

                  {isMenuDropDownOpen && (
                    <div className="dropdown-container">
                      <div className="dropdown-mobile-link">
                        <Link
                          className="mobile-link"
                          to="/web-application-pentest"
                        >
                          Web Application Pentest
                        </Link>
                        <Link className="mobile-link" to="/mobile-app-pentest">
                          Mobile Application Pentest
                        </Link>
                        <Link className="mobile-link" to="/cloud-pentest">
                          Cloud Security Assessment
                        </Link>
                        <Link className="mobile-link" to="/red-team-pentest">
                          Red Team Assessment
                        </Link>
                        <Link
                          className="mobile-link"
                          to="/infrastructure-security"
                        >
                          Infrastructure Security Testing
                        </Link>
                        <Link className="mobile-link" to="/wireless-pentest">
                          Wireless Security Testing
                        </Link>
                        <Link className="mobile-link" to="/source-code-pentest">
                          Secure Code Analysis
                        </Link>
                        <Link
                          className="mobile-link"
                          to="/it-security-assesment"
                        >
                          IT Security Training
                        </Link>
                      </div>
                    </div>
                  )}
                </div>
                <NavLink path="/trainings" title="Trainings" />
                <NavLink path="/contact" title="Contact Us" />
                <NavLink path="/blog" title="Blog" />
              </ul>
            </div>
          </div>
        </div>
        {isOpen && (
          <div className="burger-menu-container">
            <div className="parent-mobile-link">
              <Link className="mobile-link" to="/">
                Home
              </Link>
              <Link className="mobile-link" to="/about">
                About Us
              </Link>
              <Link className="mobile-link" to="/services">
                Services
              </Link>
              <Link className="mobile-link" to="/trainings">
                Trainings
              </Link>
              <Link className="mobile-link" to="/contact">
                Contact Us
              </Link>
              <Link className="mobile-link" to="/blog">
                Blog
              </Link>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Header;
