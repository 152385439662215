import React, { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import app from "../assets/images/mobile-app (1).png";
import webApp from "../assets/images/web-app.png";
import cloud from "../assets/images/cloud.png";
import network from "../assets/images/network.png";
import "../App.css";

const Trainings = () => {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);
  return (
    <div className="partners-container">
      <div className="training-details-inner-container">
        <div className="training-box-container" data-aos="fade-right">
          <div className="training-image-div">
            <img
              src={webApp}
              alt="RBSEC Phone Detail"
              className="training-box"
            />
          </div>
          <h3 className="training-box-title">
            Web Application Pentesting Training
          </h3>
          <p className="training-box-para">
            Acquire web app security skills: Learn pentesting techniques, tools,
            and tactics for robust cyber defense.
          </p>
        </div>
        <div className="training-box-container" data-aos="fade-right">
          <div className="training-image-div">
            <img src={app} alt="RBSEC Email Detail" className="training-box" />
          </div>
          <h3 className="training-box-title">
            Mobile Application Pentesting Training
          </h3>
          <p className="training-box-para">
            Equip yourself: Gain mobile app security expertise with hands-on
            Pentesting Training, tools, and strategic insights.
          </p>
        </div>
        <div className="training-box-container" data-aos="fade-left">
          <div className="training-image-div">
            <img
              src={cloud}
              alt="RBSEC Location Detail"
              className="training-box"
            />
          </div>
          <h3 className="training-box-title">Cloud Security Training</h3>
          <p className="training-box-para">
            Secure the cloud: Acquire vital expertise in our hands-on Cloud
            Security Training, mastering tools and strategies.
          </p>
        </div>
        <div className="training-box-container" data-aos="fade-left">
          <div className="training-image-div">
            <img
              src={network}
              alt="RBSEC Location Detail"
              className="training-box"
            />
          </div>
          <h3 className="training-box-title">Network Pentesting Training</h3>
          <p className="training-box-para">
            Master network security: Enroll in our hands-on Network Pentesting
            Training for essential skills, tools, and techniques.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Trainings;
