import React from "react";
import Header from "../components/Header";
import PageTitle from "../components/PageTitle";
import ServiceBlock from "../components/ServiceBlock";
import OurServices from "../components/OurServices";
import image from "../assets/images/mobile-app (1).png";
import Footer from "../components/Footer";
import Group from "../components/Group";
import Section1 from "../components/Section1";
import wpsec1 from "../assets/images/mp-sec1.png";
import wpsec2 from "../assets/images/secure-asset.png";
import wpsec3 from "../assets/images/time.png";
import wpsec4 from "../assets/images/secure-doc.png";
import wpsec5 from "../assets/images/cert.png";
import Section2 from "../components/Section2";
import Section3 from "../components/Section3";
import Section4 from "../components/Section4";
import ServiceBlockLeft from "../components/ServiceBlockLeft";
import { ScrollRestoration } from "react-router-dom";

const MobileAppScreen = () => {
  return (
    <>
      <Header />
      <PageTitle
        title="Mobile Application Pentest"
        description="In todays smartphone dominated era mobile applications have revolutionized the way we engage with consumers, customers, companies and services."
        pageTitle="Mobile Application Pentest"
        path="mobile-app-pentest"
      />
      <ServiceBlock
        title="Securing Digital Interactions: Mobile Application Security Assessment"
        description="As mobile apps increasingly become the primary mode of digital engagement, their security can't be overstated. Our Mobile Application Security Assessment service is designed to fortify your mobile apps against the rising tide of cybersecurity threats."
        image={image}
        subheading="Mobile Application Security"
        altText="Securing Digital Interactions: Mobile Application Security Assessment"
      />
      <Section3
        subheading="Application Assessment"
        heading="The Imperative of Mobile Application Security Assessment"
        para="In an era where mobile apps govern digital media usage, the security of these apps is crucial. As your customers increasingly interact through mobile apps, they also become a prime target for mobile malware and other cybersecurity threats. Whether you're a burgeoning start-up or a multinational corporation, a single breach can violate your company's and customers' privacy."
        imageUrl={wpsec1}
        altText="The Imperative of Mobile Application Security Assessment"
      />
      <Section1
        subheading="prioritizing security"
        heading="Establishing User Trust Through Security"
        para="In the fiercely competitive digital market, security can be a unique value proposition that sets your brand apart. Our Mobile Application Security Assessment helps you establish a trust quotient within your mobile app users by prioritizing security."
        imageUrl={wpsec2}
        altText="Establishing User Trust Through Security"
      />
      <Section2
        subheading="Secure the assets"
        heading="Protecting Time, Finances, and Reputation"
        para="The costs associated with a security breach—both financial and reputational—can be substantial. Legal fees, negative PR, and the potential loss of customer trust can have a long-term impact on your brand. Our assessment service helps you proactively secure these assets, saving you time, money, and reputation."
        imageUrl={wpsec3}
        altText="Protecting Time, Finances, and Reputation"
      />

      <ServiceBlockLeft
        title="Identifying Vulnerabilities"
        description="Our service is designed to uncover complex vulnerabilities and security loopholes that might otherwise be missed. By identifying and addressing these vulnerabilities, you can effectively safeguard your mobile application and user data against cyberattacks."
        image={wpsec4}
        subheading="Vulnerablities"
        altText="Identifying Vulnerabilities"
      />
      <Section4
        subheading="Standards and Certificates"
        heading="Achieving Certifications and Compliance"
        para="Compliance with security standards and certifications not only builds brand credibility but also opens up opportunities for partnerships and investments. Our Mobile Application Security Assessment ensures that your mobile apps meet these standards, preparing you for security audits and fostering trust among potential partners, investors, and clients."
        imageUrl={wpsec5}
        altText="Achieving Certifications and Compliance"
      />
      <OurServices />
      <Footer />
      <Group />
      <ScrollRestoration />
    </>
  );
};

export default MobileAppScreen;
