import React from "react";
import Header from "../components/Header";
import PageTitle from "../components/PageTitle";
import AboutPage from "../components/AboutPage";
import Footer from "../components/Footer";
import Group from "../components/Group";
import { ScrollRestoration } from "react-router-dom";

const AboutScreen = () => {
  return (
    <>
      <Header />
      <PageTitle
        title="About Us"
        description="We believe in the power of secure digital environments to unlock potential and drive progress."
        pageTitle="About Us"
        path="about"
      />
      <AboutPage />
      <Footer />
      <Group />
      <ScrollRestoration />
    </>
  );
};

export default AboutScreen;
