import React from "react";
import Header from "../components/Header";
import PageTitle from "../components/PageTitle";
import Trainings from "../components/Trainings";
import TrainingBlock from "../components/TrainingBlock";
import Footer from "../components/Footer";
import Group from "../components/Group";
import { ScrollRestoration } from "react-router-dom";

const Certificates = () => {
  return (
    <>
      <Header />
      <PageTitle
        title="Trainings"
        description="We Believe in a Training Plan that is Based on Real-World Scenarios, Affordable, Frequently updated, Proctored (Authentic and Credible), Flexible (Online and on-demand)"
        pageTitle="Trainings"
        path="trainings"
      />
      <TrainingBlock />
      <Trainings />
      <Footer />
      <Group />
      <ScrollRestoration />
    </>
  );
};

export default Certificates;
