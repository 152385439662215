import React from "react";
import { Link } from "react-router-dom";
import pageImage from "../assets/images/banner-bg-1.png";
import { FaAngleDoubleRight } from "react-icons/fa";
import "../App.css";

const PageTitle = ({ title, description, pageTitle, path }) => {
  const pathTo = `/${path}`;
  return (
    <div
      className="page-title-container"
      style={{
        backgroundImage: `url(${pageImage})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <div className="page-container">
        <h1 className="page-title">{title}</h1>
        <p className="page-description">{description}</p>
        <div className="page-transition">
          <span className="page-span">
            <Link to="/" className="link">
              Home
            </Link>
          </span>
          <span className="page-span">
            <FaAngleDoubleRight
              style={{
                color: "white",
                width: "24px",
                height: "24px",
                marginLeft: "10px",
                marginRight: "10px",
              }}
            />
          </span>
          <span className="page-span">
            <Link to={pathTo} className="link">
              {pageTitle}
            </Link>
          </span>
        </div>
      </div>
    </div>
  );
};

export default PageTitle;
