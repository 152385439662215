import React from "react";
import { Link } from "react-router-dom";
import footerBg from "../assets/images/banner-bg-1.png";
import image from "../assets/logo/rbsec-logo-1.png";
import { BsFacebook, BsTwitter, BsLinkedin } from "react-icons/bs";

const Footer = () => {
  return (
    <div className="footer-container">
      <div
        className="footer-inner-container"
        style={{
          backgroundImage: `url(${footerBg})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "100%",
          backgroundPosition: "top",
        }}
      >
        <div className="footer-wrapper">
          <div>
            <h3 className="footer-box-title">About Us</h3>
            <img src={image} alt="rbsec group" className="img-logo" />
            <p className="footer-box-description">
              Our specialized services are designed to mitigate risks, prevent
              security lapses, and create robust defense systems for your
              digital resources. We combine cutting-edge technology with
              profound industry expertise to ensure your information's
              security and integrity.
            </p>
            <div className="footer-icons">
              <Link to="https://www.linkedin.com/company/rbsec-tech-private-limited">
                <BsLinkedin className="social-icon" />
              </Link>
              <Link to="https://twitter.com/RbsecTech">
                <BsTwitter className="social-icon" />
              </Link>
              <Link to="https://www.facebook.com/profile.php?id=100095646094542&mibextid=ZbWKwL">
                <BsFacebook className="social-icon" />
              </Link>
            </div>
          </div>
        </div>
        <div className="footer-wrapper-2">
          <div className="footer-box">
            <h3 className="footer-box-title">Quick Links</h3>
            <div className="footer-menu">
              <Link className="footer-link" to="/web-application-pentest">
                Web Application Security Assessment
              </Link>
              <Link className="footer-link" to="/mobile-app-pentest">
                Mobile Application Security Assessment
              </Link>
              <Link className="footer-link" to="/services">
                Services
              </Link>
              <Link className="footer-link" to="/trainings">
                Trainings
              </Link>
              <Link className="footer-link" to="/contact">
                Contact Us
              </Link>
              <Link className="footer-link" to="/blog">
                Blog
              </Link>
            </div>
          </div>
          <div className="footer-box">
            <h3 className="footer-box-title">Contact Us</h3>
            <p className="footer-contact-address">RBSec Org</p>
            <p className="footer-contact-address">
              <span style={{ fontWeight: "bold" }}>Address: </span>
              Rajasthan,India
              <br />
              <span style={{ fontWeight: "bold" }}>Phone: </span>{" "}
              +91-946-089-2887, +91-921-689-2887
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
