import React from "react";
import OurServices from "../components/OurServices";
import ServiceBlock from "../components/ServiceBlock";
import PageTitle from "../components/PageTitle";
import Header from "../components/Header";
import image from "../assets/images/secure-it.png";
import Footer from "../components/Footer";
import Group from "../components/Group";
import Section1 from "../components/Section1";
import wpsec1 from "../assets/images/IF2.png";
import wpsec2 from "../assets/images/IF3.png";
import wpsec3 from "../assets/images/IF4.png";
import wpsec4 from "../assets/images/IF5.png";
import Section2 from "../components/Section2";
import Section3 from "../components/Section3";
import Section4 from "../components/Section4";
import { ScrollRestoration } from "react-router-dom";

const ITSecurityScreen = () => {
  return (
    <>
      <Header />
      <PageTitle
        title="IT Security Training"
        description="Our IT Security Training programs are designed to build and enhance your team's cybersecurity skills, arming them with the knowledge and competencies to defend against evolving cyber threats."
        pageTitle="IT Security Training"
        path="it-security-assesment"
      />
      <ServiceBlock
        title="The Significance of IT Security Training"
        description="In the ever-evolving cybersecurity landscape, knowledge is power. It is critical to equip your team with the latest insights and skills in IT security to proactively defend your digital assets. Our IT Security Training programs offer comprehensive and up-to-date training modules that empower your team to combat evolving cyber threats."
        image={image}
        subheading="Security Training"
        altText="The Significance of IT Security Training"
      />
      <Section1
        subheading="building cyber resilience"
        heading="Cultivating Cyber Resilience"
        para="Our IT Security Training programs focus on building cyber resilience within your team. By understanding the latest threats and defensive strategies, your team will be better prepared to identify potential security risks and respond effectively, thereby protecting your organization's valuable data and systems."
        imageUrl={wpsec1}
        altText="Cultivating Cyber Resilience"
      />
      <Section2
        subheading="IT security principles"
        heading="Unleashing Potential with Expert Guidance"
        para="Our IT Security Training is delivered by seasoned cybersecurity experts with a wealth of industry experience. They provide practical insights and hands-on exercises that bring theoretical knowledge to life, helping your team gain a deep understanding of IT security principles and their real-world applications."
        imageUrl={wpsec2}
        altText="Unleashing Potential with Expert Guidance"
      />
      <Section3
        subheading="emphasizes proactive learning"
        heading="Proactive Learning for Proactive Defense"
        para="Our approach to IT Security Training emphasizes proactive learning. We aim to equip your team not only with the knowledge of existing threats but also the skills to anticipate and respond to new forms of cyberattacks. This forward-thinking approach ensures your team stays ahead of the cybersecurity curve."
        imageUrl={wpsec3}
        altText="Proactive Learning for Proactive Defense"
      />
      <Section4
        subheading="advance technique"
        heading="Comprehensive Learning for All Levels"
        para="Whether your team is new to IT security or seeking to advance their existing skills, our training programs cater to all levels. We offer a variety of modules that range from basic cybersecurity principles to advanced techniques for defending against complex cyber threats."
        imageUrl={wpsec4}
        altText="Comprehensive Learning for All Levels"
      />

      <OurServices />
      <Footer />
      <Group />
      <ScrollRestoration />
    </>
  );
};

export default ITSecurityScreen;
