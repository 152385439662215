import React from "react";
import Header from "../components/Header";
import PageTitle from "../components/PageTitle";
import ServiceBlock from "../components/ServiceBlock";
import OurServices from "../components/OurServices";
import image from "../assets/images/services-img4.png";
import Footer from "../components/Footer";
import Group from "../components/Group";
import Section1 from "../components/Section1";
import wpsec1 from "../assets/images/privacy.png";
import wpsec2 from "../assets/images/empower.png";
import wpsec3 from "../assets/images/discuss.png";
import wpsec4 from "../assets/images/find.png";
import wpsec5 from "../assets/images/response.png";
import Section2 from "../components/Section2";
import Section3 from "../components/Section3";
import Section4 from "../components/Section4";
import ServiceBlockLeft from "../components/ServiceBlockLeft";
import { ScrollRestoration } from "react-router-dom";

const RedTeamScreen = () => {
  return (
    <>
      <Header />
      <PageTitle
        title="Red Team Assesment"
        description="Our primary aim, during a red team exercise is to assess your organizations security measures by conducting a black box evaluation."
        pageTitle="Red Team Assesment"
        path="red-team-pentest"
      />
      <ServiceBlock
        title="Proactive Cyber Defense: Red Team Assessment"
        description="Taking the front foot in cybersecurity, our Red Team Assessment service offers comprehensive, real-world evaluation of your organization's resilience against potential cyber threats."
        image={image}
        subheading="RED TEAM ASSESSMENT"
        altText="Proactive Cyber Defense: Red Team Assessment"
      />
      <Section1
        subheading="Planned Approach"
        heading="The Value of Proactive Cyber Defense Measures"
        para="Our Red Team Assessment is a meticulously planned, offensive approach that mirrors real-world malicious threats. Through this service, we penetrate your defenses and gauge your readiness to detect, mitigate and respond to targeted cyber attacks."
        imageUrl={wpsec1}
        altText="The Value of Proactive Cyber Defense Measures"
      />
      <Section4
        subheading="Effectiveness Setup"
        heading="Uncover Hidden Vulnerabilities"
        para="More than just revealing vulnerabilities, our Red Team Assessment shines a spotlight on your organization's security strongholds. By testing against advanced adversary behaviors, we help you comprehend your security setup's effectiveness. This knowledge equips you to solidify your defense mechanisms and construct a formidable cybersecurity framework."
        imageUrl={wpsec4}
        altText="Identifying Fortitudes"
      />
      <Section3
        subheading="Benchmarks"
        heading="Benchmarking People, Process, and Technology"
        para="In the aftermath of a cyberattack, determining the exact timeline and source of the breach can be daunting, thereby complicating the response process. Our Red Team Assessment methodology precisely logs each action, allowing a comprehensive measure of your people, processes, and technology's detection and response capabilities. This information is instrumental in pinpointing improvement areas and implementing measures to fortify your cybersecurity.        "
        imageUrl={wpsec3}
        altText="Benchmarking People, Process, and Technology"
      />

      <Section2
        subheading="BLUE TEAM"
        heading="Empowering the Blue Team"
        para="A well-trained defensive team is an integral component of effective cybersecurity. Our Red Team Assessment services extend beyond testing to provide technical training to your Blue Team, support in refining your incident response plan, and address any potential flaws in your response strategy. This proactive strategy ensures your team is battle-ready to mitigate real-world attacks efficiently."
        imageUrl={wpsec2}
        altText="Empowering the Blue Team"
      />
      <ServiceBlockLeft
        title="Enhancing Your Incident Response"
        description="Our Red Team Assessment is tailored to help you identify, analyze, and mitigate potential security breaches effectively. We conduct a thorough review of your current incident response protocols and suggest improvements. Consequently, you're well-prepared and adept at managing potential security breaches and cyber attacks."
        image={wpsec5}
        subheading="Tailored Services"
        altText="Enhancing Your Incident Response"
      />
      <OurServices />
      <Footer />
      <Group />
      <ScrollRestoration />
    </>
  );
};

export default RedTeamScreen;
