import React from "react";
import PageTitle from "../components/PageTitle";
import Header from "../components/Header";
import ServiceBlock from "../components/ServiceBlock";
import OurServices from "../components/OurServices";
import image from "../assets/images/cloud.png";
import Footer from "../components/Footer";
import Group from "../components/Group";
import Section1 from "../components/Section1";
import wpsec1 from "../assets/images/cloud-1.png";
import wpsec2 from "../assets/images/secure-trust.png";
import wpsec3 from "../assets/images/approach.png";
import wpsec4 from "../assets/images/trust-tech.png";
import wpsec5 from "../assets/images/time.png";
import Section2 from "../components/Section2";
import Section3 from "../components/Section3";
import Section4 from "../components/Section4";
import ServiceBlockLeft from "../components/ServiceBlockLeft";
import { ScrollRestoration } from "react-router-dom";

const CloudScreen = () => {
  return (
    <>
      <Header />
      <PageTitle
        title="Cloud Security Assesment"
        description="Our team of experts, in cloud security is here to assist you in enhancing the security of your cloud environment."
        pageTitle="Cloud Security Assesment"
        path="cloud-pentest"
      />

      <ServiceBlock
        title="Guarding Your Digital Sky: Cloud Security Assessment"
        description="In the ever-evolving digital landscape, safeguarding cloud servers has become a vital aspect of cybersecurity. Our Cloud Security Assessment service offers meticulous examination and protection strategies for your cloud environment."
        image={image}
        subheading="Examination & Protection"
        altText="Guarding Your Digital Sky: Cloud Security Assessment"
      />
      <Section1
        subheading="Non Negotiable Priority"
        heading="The Necessity of Cloud Security Assessment"
        para="Cloud servers are the heart of many modern businesses, storing and processing invaluable information. Thus, their security remains a non-negotiable priority. With a shared responsibility model, both cloud providers and users need to ensure the data's security. Our Cloud Security Assessment service, backed by years of expertise, helps you protect your cloud servers. We employ a layered security review, enabling scalable and secure application development while identifying potential vulnerabilities in your cloud environment."
        imageUrl={wpsec1}
        altText="The Necessity of Cloud Security Assessment"
      />
      <Section2
        subheading="Trust"
        heading="Establishing User Trust Through Enhanced Security"
        para="Amid the competitive digital market, security can serve as a compelling differentiator for your brand. Our Cloud Security Assessment allows you to establish a trust bond with your users by positioning security at the core of your brand's proposition."
        imageUrl={wpsec2}
        altText="Establishing User Trust Through Enhanced Security"
      />
      <Section3
        subheading="robust recommendations"
        heading="Boosting Cloud Resilience"
        para="Our team of cloud security experts provides robust recommendations to respond to vulnerabilities. This proactive approach ensures a minor issue does not escalate into a full-blown breach. Our services also help you recover faster and minimize the surface area exposure of your servers, thereby reducing your attack susceptibility."
        imageUrl={wpsec3}
        altText="Boosting Cloud Resilience"
      />
      <Section4
        subheading="peace of mind"
        heading="Assurance of a Secure Infrastructure"
        para="Our Cloud Security Assessment service includes a thorough review and reconfiguration of your infrastructure. This step ensures there are no potential access points for external threats or internal users seeking unauthorized access, providing you with peace of mind about your cloud environment's security."
        imageUrl={wpsec4}
        altText="Assurance of a Secure Infrastructure"
      />
      <ServiceBlockLeft
        title="Preserving Time and Reputation"
        description="Securing your infrastructure effectively safeguards not only your precious time but also your reputation. By proactively securing your infrastructure, you can prevent potential attacks that aim to tarnish your reputation and disrupt your operations."
        image={wpsec5}
        subheading="Time and Reputation"
        altText="Preserving Time and Reputation"
      />
      <OurServices />
      <Footer />
      <Group />
      <ScrollRestoration />
    </>
  );
};

export default CloudScreen;
