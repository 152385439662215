import React from "react";
import ServiceBlock from "../components/ServiceBlock";
import image from "../assets/images/web-app.png";
import Header from "../components/Header";
import PageTitle from "../components/PageTitle";
import OurServices from "../components/OurServices";
import Footer from "../components/Footer";
import Group from "../components/Group";
import Section1 from "../components/Section1";
import wpsec1 from "../assets/images/wp-sec1.png";
import wpsec2 from "../assets/images/wsec2.png";
import wpsec3 from "../assets/images/wp-sec3.png";
import wpsec4 from "../assets/images/fire.png";
import wpsec5 from "../assets/images/certificate.png";
import Section2 from "../components/Section2";
import Section3 from "../components/Section3";
import Section4 from "../components/Section4";
import ServiceBlockLeft from "../components/ServiceBlockLeft";
import { ScrollRestoration } from "react-router-dom";

const WebAppScreen = () => {
  return (
    <>
      <Header />
      <PageTitle
        title="Web Application Pentest"
        description="Web applications are critical for business success but are also attractive targets for cybercriminals."
        pageTitle="Web Application Pentest"
        path="web-application-pentest"
      />
      <ServiceBlock
        title="Why is Web Application Penetration Testing Essential?"
        description="In the interconnected world of today, the internet has become an integral part of our everyday lives. This widespread use of online platforms has led to an exponential growth in the data generated and stored on the web, making it a fertile ground for cybercriminals. Web applications, in particular, are often targeted due to their accessibility and the wealth of data they hold. This is where our Web Application Penetration Testing service comes in, ensuring your digital assets' security and resilience."
        image={image}
        subheading="Web Application Pentest"
        altText="Why is Web Application Penetration Testing Essential?"
      />
      <Section1
        subheading="Protection"
        heading="Mitigate Cyber Threats and Enhance Security"
        para="Cyber threats have evolved significantly over the years, becoming increasingly sophisticated and damaging. These threats are not always visible; in fact, the most damaging attacks are often those that remain undetected until it's too late. Web Application Penetration Testing helps identify these hidden threats, allowing for the mitigation of potential vulnerabilities before they can be exploited."
        imageUrl={wpsec1}
        altText="Mitigate Cyber Threats and Enhance Security"
      />
      <Section2
        subheading="Trust"
        heading="Establish Trust with Your Users"
        para="In the digital realm, trust is an invaluable commodity. Users are more likely to engage with websites that they perceive as secure. With our Web Application Penetration Testing service, you can assure your users that their data is protected, fostering a sense of trust and loyalty. This not only enhances user experience but also helps your website stand out from the competition."
        imageUrl={wpsec2}
        altText="Establish Trust with Your Users"
      />
      <Section3
        subheading="Legal Penalties"
        heading="Avoid Costly Lawsuits and Reputational Damage"
        para="A single security breach can result in significant financial and reputational damage. In addition to the potential loss of sensitive data, businesses can face substantial legal penalties and loss of customer trust. By proactively identifying and addressing vulnerabilities, our Web Application Penetration Testing service can help you prevent such incidents, saving your business from costly lawsuits and the associated reputational harm."
        imageUrl={wpsec3}
        altText="Avoid Costly Lawsuits and Reputational Damage"
      />
      <Section4
        subheading="address vulnerabilities"
        heading="Uncover Hidden Vulnerabilities"
        para="Web applications are complex systems with numerous components, each potentially harboring security weaknesses. Our Penetration Testing service employs a comprehensive approach, scrutinizing every aspect of your web application to detect and address vulnerabilities. This thorough examination ensures that even the most inconspicuous weaknesses are spotted and remedied, fortifying your web application against potential cyberattacks."
        imageUrl={wpsec4}
        altText="Uncover Hidden Vulnerabilities"
      />
      <ServiceBlockLeft
        title="Achieve Compliance and Certifications"
        description="With cyber threats becoming an ever-growing concern, many industries now require businesses to comply with specific cybersecurity standards. These certifications not only ensure that your business is following best security practices but also boost your credibility. Our Web Application Penetration Testing service can help you meet these standards, facilitating smoother audits and making your business more attractive to potential partners, investors, and clients.        "
        image={wpsec5}
        subheading="Web Application Pentest"
        altText="Achieve Compliance and Certifications"
      />
      <OurServices />
      <Footer />
      <Group />
      <ScrollRestoration />
    </>
  );
};

export default WebAppScreen;
