import React from "react";
import Header from "../components/Header";
import PageTitle from "../components/PageTitle";
import ServiceBlock from "../components/ServiceBlock";
import OurServices from "../components/OurServices";
import image from "../assets/images/wireless.png";
import Footer from "../components/Footer";
import Group from "../components/Group";
import Section1 from "../components/Section1";
import wpsec1 from "../assets/images/WL2.png";
import wpsec2 from "../assets/images/WL3.png";
import wpsec3 from "../assets/images/WL4.png";
import wpsec4 from "../assets/images/WL5.png";
import wpsec5 from "../assets/images/WL6.png";
import Section2 from "../components/Section2";
import Section3 from "../components/Section3";
import Section4 from "../components/Section4";
import ServiceBlockLeft from "../components/ServiceBlockLeft";
import { ScrollRestoration } from "react-router-dom";

const WirelessScreen = () => {
  return (
    <>
      <Header />
      <PageTitle
        title="Wireless Security Testing"
        description="Wireless networks provide enhanced user productivity but may inadvertently expose your internal network to external threats."
        pageTitle="Wireless Security Testing"
        path="wireless-pentest"
      />
      <ServiceBlock
        title="Defending Your Invisible Frontiers: Wireless Security Testing"
        description="Our Wireless Security Testing service employs state-of-the-art methodologies to protect your wireless networks, ensuring safe and secure data transmission."
        image={image}
        subheading="state-of-the-art method"
        altText="Defending Your Invisible Frontiers: Wireless Security Testing"
      />
      <Section1
        subheading="safeguard your wireless networks"
        heading="The Imperative of Wireless Security Testing"
        para="In the age of ubiquitous wireless networks, the security of these networks becomes a prime concern. From Wi-Fi networks to Bluetooth connections, each wireless touchpoint represents a potential vulnerability. Our Wireless Security Testing service helps safeguard your wireless networks from potential threats, securing your data transmissions."
        imageUrl={wpsec1}
        altText="The Imperative of Wireless Security Testing"
      />
      <Section2
        subheading="Trust"
        heading="Fostering User Trust Through Secure Connections"
        para="In today's digital era, users place immense trust in brands that prioritize network security. Our Wireless Security Testing service helps you build this trust by ensuring the secure transmission of data across your wireless networks, demonstrating your commitment to protecting user data."
        imageUrl={wpsec2}
        altText="Fostering User Trust Through Secure Connections"
      />
      <Section3
        subheading="exhaustive evaluation"
        heading="Reducing Risks with Thorough Assessment"
        para="Our team of experts carries out an exhaustive evaluation of your wireless networks, pinpointing potential vulnerabilities and points of entry for cyber threats. By proactively addressing these issues, we help to minimize the risk of successful cyberattacks, effectively fortifying your wireless network security."
        imageUrl={wpsec3}
        altText="Reducing Risks with Thorough Assessment"
      />
      <Section4
        subheading="Cutting-Edge Detection"
        heading="Proactive Detection and Resolution"
        para="Wireless security isn't merely about setting up encrypted connections—it also involves a proactive approach to identifying and resolving potential vulnerabilities. Our service integrates cutting-edge detection tools with specialist expertise to discover and rectify security gaps before they can be exploited by cyber threats."
        imageUrl={wpsec4}
        altText="Proactive Detection and Resolution"
      />
      <ServiceBlockLeft
        title="In-Depth Analysis for Comprehensive Protection"
        description="We undertake a comprehensive analysis of your wireless network security, examining elements like access controls, encryption methods, and vulnerability patches. This rigorous assessment helps us to identify potential weak spots and devise remediation strategies, reinforcing the overall security of your wireless networks."
        image={wpsec5}
        subheading="comprehensive analysis"
        altText="In-Depth Analysis for Comprehensive Protection"
      />
      <OurServices />
      <Footer />
      <Group />
      <ScrollRestoration />
    </>
  );
};

export default WirelessScreen;
