import React, { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import email from "../assets/images/contact/contact-email-icon.png";
import phone from "../assets/images/contact/contact-phone-icon.png";
import location from "../assets/images/contact/contact-location-icon.png";

const ContactDetails = () => {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);
  return (
    <div className="partners-container">
      <div className="contact-details-inner-container">
        <div className="contact-box-container" data-aos="fade-right">
          <img src={phone} alt="RBSEC Phone Detail" className="contact-box" />
          <h3 className="contact-box-title">Phone</h3>
          <p className="contact-box-para">+91-94608 92887, +91-921-689-2887</p>
        </div>
        <div className="contact-box-container" data-aos="fade-bottom">
          <img src={email} alt="RBSEC Email Detail" className="contact-box" />
          <h3 className="contact-box-title">Email</h3>
          <p className="contact-box-para">info@rbsec.org</p>
        </div>
        <div className="contact-box-container" data-aos="fade-left">
          <img
            src={location}
            alt="RBSEC Location Detail"
            className="contact-box"
          />
          <h3 className="contact-box-title">Location</h3>
          <p className="contact-box-para">Rajasthan, India</p>
        </div>
      </div>
      <div className="about-bottom">
        <p className="box-20-para">
          Please note that our office hours are from 9 AM to 5 PM (Monday to
          Friday).
        </p>
      </div>
    </div>
  );
};

export default ContactDetails;
