import React from "react";
import "../App.css";

const Section4 = ({ subheading, heading, para, imageUrl, altText }) => {
  return (
    <div className="section-1-container">
      <div className="section-4-sub-container">
        <div className="section-1-left-container">
          <img src={imageUrl} alt={altText} className="sec1-img" />
        </div>
        <div className="section-1-right-container">
          <div className="section-1-right-sub-heading-container">
            <p className="section-1-right-sub-heading">{subheading}</p>
          </div>
          <div className="section-1-right-heading-container">
            <h1 className="section-1-right-heading">{heading}</h1>
          </div>
          <div className="section-1-right-para-container">
            <p className="section-4-right-para">{para}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section4;
