import React, { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import section1Image from "../assets/images/training.png";
import "../App.css";

const TrainingBlock = () => {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);
  return (
    <>
      <div className="about-page-container">
        <div className="about-page-section-1" data-aos="fade-right">
          <div className="about-page-section-1-left">
            <p className="about-tagline">TRAINING AT RBSEC</p>
            <p
              style={{
                fontFamily: "WebRegular",
                fontSize: "32px",
                lineHeight: "48px",
                color: "#fff",
                marginTop: "0px",
                marginBottom: "0px",
              }}
            >
              A Team of Experienced <br />
              Trainers
            </p>
            <p
              style={{
                fontFamily: "WebRegular",
                fontSize: "19px",
                lineHeight: "27px",
                color: "#afb6d2",
                marginTop: "0px",
                marginBottom: "0px",
              }}
            >
              Each course is designed to equip you with the knowledge and
              hands-on experience needed to excel in the respective field. We
              are committed to providing top-notch training to help you stay
              ahead in the ever-evolving world of cybersecurity.
            </p>
          </div>
          <div className="about-page-section-1-right">
            <img
              src={section1Image}
              data-aos="fade-left"
              className="about-section-image"
              alt="RBSEC Team"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default TrainingBlock;
