import React from "react";
import OurServices from "../components/OurServices";
import ServiceBlock from "../components/ServiceBlock";
import PageTitle from "../components/PageTitle";
import Header from "../components/Header";
import image from "../assets/images/IT1.png";
import Footer from "../components/Footer";
import Group from "../components/Group";
import Section1 from "../components/Section1";
import wpsec1 from "../assets/images/IT2.png";
import wpsec2 from "../assets/images/security.png";
import wpsec3 from "../assets/images/IT4.png";
import wpsec4 from "../assets/images/IT5.png";
import wpsec5 from "../assets/images/IT6.png";
import Section2 from "../components/Section2";
import Section3 from "../components/Section3";
import Section4 from "../components/Section4";
import ServiceBlockLeft from "../components/ServiceBlockLeft";
import { ScrollRestoration } from "react-router-dom";

const NetworkSecurityScreen = () => {
  return (
    <>
      <Header />
      <PageTitle
        title="Infrastructure Security Testing"
        description="Infrastructure Security Testing services are performed to detect and validate vulnerabilities."
        pageTitle="Infrastructure Security Testing"
        path="infrastructure-security"
      />
      <ServiceBlock
        title="Strengthening Your Digital Infrastructure: Infrastructure Security Assessment"
        description="Our Infrastructure Security Assessment service offers robust protection strategies for your network, providing a safe and secure environment for your data."
        image={image}
        subheading="Infrastructure Security"
        altText="Strengthening Your Digital Infrastructure: Infrastructure Security Assessment"
      />
      <Section1
        subheading="IMPortance"
        heading="The Importance of Infrastructure Security Assessment"
        para="Today's digital landscape is riddled with potential threats that can compromise your network infrastructure. These can lead to data breaches, operational disruption, and damage to your brand's reputation. Our Infrastructure Security Assessment service is designed to proactively counter these risks, strengthening the security fabric of your network infrastructure."
        imageUrl={wpsec1}
        altText="The Importance of Infrastructure Security Assessment"
      />
      <Section2
        subheading="protect user data"
        heading="Building Confidence through Robust Network Security"
        para="Trust in a brand's ability to protect user data is a critical factor in today's digitally connected world. Our Infrastructure Security Assessment allows you to build user confidence by demonstrating commitment to the highest security standards. We help you create a secure and reliable network that ensures the integrity and confidentiality of your data."
        imageUrl={wpsec2}
        altText="Building Confidence through Robust Network Security"
      />
      <Section3
        subheading="examination of your network"
        heading="Maximizing Protection, Minimizing Threats"
        para="Our expert team carries out a thorough examination of your network infrastructure, identifying vulnerabilities and potential points of ingress for cyber threats. Our proactive approach in eliminating these vulnerabilities minimizes the risk of a successful cyberattack, offering your infrastructure a strong line of defense."
        imageUrl={wpsec3}
        altText="Maximizing Protection, Minimizing Threats"
      />
      <Section4
        subheading="remediate vulnerabilities"
        heading="Proactive Detection and Remediation"
        para="Infrastructure security is not just about installing firewalls and other defense measures—it involves a proactive approach to detect and remediate vulnerabilities. Our service offers you a comprehensive security strategy, combining advanced detection tools with expert knowledge to uncover and mitigate potential threats before they can exploit your network."
        imageUrl={wpsec4}
        altText="Proactive Detection and Remediation"
      />
      <ServiceBlockLeft
        title="In-Depth Analysis for Comprehensive Protection"
        description="We undertake a comprehensive analysis of your wireless network security, examining elements like access controls, encryption methods, and vulnerability patches. This rigorous assessment helps us to identify potential weak spots and devise remediation strategies, reinforcing the overall security of your wireless networks."
        image={wpsec5}
        subheading="identify potential weak spots"
        altText="In-Depth Analysis for Comprehensive Protection"
      />
      <OurServices />
      <Footer />
      <Group />
      <ScrollRestoration />
    </>
  );
};

export default NetworkSecurityScreen;
