import React from "react";
import Header from "../components/Header";
import PageTitle from "../components/PageTitle";
import Footer from "../components/Footer";
import Group from "../components/Group";
import { ScrollRestoration } from "react-router-dom";

const NotFoundScreen = () => {
  return (
    <>
      <Header />
      <PageTitle
        title="Page Not Found"
        description="Oops! The page you are looking for does not exist. It might have been moved or deleted."
        pageTitle="No Page"
        path="pagenotfound"
      />
      <Footer />
      <Group />
      <ScrollRestoration />
    </>
  );
};

export default NotFoundScreen;
