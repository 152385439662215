import React from "react";
import Header from "../components/Header";
import PageTitle from "../components/PageTitle";
import Footer from "../components/Footer";
import Group from "../components/Group";
import { ScrollRestoration } from "react-router-dom";

const BlogScreen = () => {
  return (
    <>
      <Header />
      <PageTitle
        title="Blog"
        description="Coming Soon"
        pageTitle="Blog"
        path="blog"
      />
      <Footer />
      <Group />
      <ScrollRestoration />
    </>
  );
};

export default BlogScreen;
