import React from "react";
import "../App.css";

const Protect = ({ image, title, description }) => {
  return (
    <div className="row-space-between-protect">
      <img src={image} className="shield" alt="Shield" />
      <div className="col-1">
        <h3 className="count-title-mid-1">{title}</h3>
        <p className="count-para-1">{description}</p>
      </div>
    </div>
  );
};

export default Protect;
