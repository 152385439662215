import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import ReactDOM from "react-dom/client";
import "./index.css";
import HomeScreen from "./screens/HomeScreen";
import AboutScreen from "./screens/AboutScreen";
import ContactUsScreen from "./screens/ContactUsScreen";
import NotFoundScreen from "./screens/NotFoundScreen";
import OurTeamScreen from "./screens/OurTeamScreen";
import FaqsScreen from "./screens/FaqsScreen";
import BlogScreen from "./screens/BlogScreen";
import ServicesScreen from "./screens/ServicesScreen";
import "./fonts/TitilliumWeb-Black.ttf";
import "./fonts/TitilliumWeb-Bold.ttf";
import "./fonts/TitilliumWeb-Light.ttf";
import "./fonts/TitilliumWeb-Regular.ttf";
import "./fonts/TitilliumWeb-SemiBold.ttf";
import Certificates from "./screens/Certificates";
import WebAppScreen from "./screens/WebAppScreen";
import MobileAppScreen from "./screens/MobileAppScreen";
import WirelessScreen from "./screens/WirelessScreen";
import SourceCodeScreen from "./screens/SourceCodeScreen";
import NetworkSecurityScreen from "./screens/NetworkSecurityScreen";
import CloudScreen from "./screens/CloudScreen";
import RedTeamScreen from "./screens/RedTeamScreen";
import ITSecurityScreen from "./screens/ITSecurityScreen";

const router = createBrowserRouter([
  {
    path: "/",
    element: <HomeScreen />,
  },
  {
    path: "/about",
    element: <AboutScreen />,
  },
  {
    path: "/contact",
    element: <ContactUsScreen />,
  },
  {
    path: "/notfound",
    element: <NotFoundScreen />,
  },
  {
    path: "/pages/ourteam",
    element: <OurTeamScreen />,
  },
  {
    path: "/pages/faqs",
    element: <FaqsScreen />,
  },
  {
    path: "/blog",
    element: <BlogScreen />,
  },
  {
    path: "/services",
    element: <ServicesScreen />,
  },
  {
    path: "/trainings",
    element: <Certificates />,
  },
  {
    path: "/web-application-pentest",
    element: <WebAppScreen />,
  },
  {
    path: "/mobile-app-pentest",
    element: <MobileAppScreen />,
  },
  {
    path: "/wireless-pentest",
    element: <WirelessScreen />,
  },
  {
    path: "/source-code-pentest",
    element: <SourceCodeScreen />,
  },
  {
    path: "/infrastructure-security",
    element: <NetworkSecurityScreen />,
  },
  {
    path: "/cloud-pentest",
    element: <CloudScreen />,
  },
  {
    path: "/red-team-pentest",
    element: <RedTeamScreen />,
  },
  {
    path: "/it-security-assesment",
    element: <ITSecurityScreen />,
  },
  {
    path: "*",
    element: <NotFoundScreen />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <RouterProvider router={router}></RouterProvider>
  </React.StrictMode>
);
